<template>
    <div>
        <v-dialog v-model="imgdialog" max-width="450px">
            <v-card tile>
                <v-card-text>
                    <v-container>
                        <v-img
                                contain
                                max-height="150"
                                :src="selectedSrc"
                        ></v-img>
                    </v-container>
                </v-card-text>

                <v-divider class="primary"/>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="() => {imgdialog=false}">
                        Back
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="450px" persistent>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                    Create
                </v-btn>
            </template>
            <v-card tile>
                <v-card-title class="primary white--text">
                    <span class="text-h5"><v-icon large color="white" class="mr-2">{{formIcon}}</v-icon>{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row no-gutters>
                            <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedImage">
                                <v-img
                                        contain
                                        max-height="150"
                                        :src="selectedImage"
                                ></v-img>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input
                                        label="Thumbnail Image"
                                        outlined
                                        dense
                                        @change="getImage"
                                        v-model="editedItem.thumbnail_url"
                                        prepend-icon=""
                                        append-icon="mdi-image"
                                ></v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        outlined
                                        dense
                                        v-model="editedItem.folder_name"
                                        :error-messages="nameErrors"
                                        required
                                        @input="editedIndex == -1 ? $v.editedItem.folder_name.$touch(): null"
                                        @blur="editedIndex == -1 ? $v.editedItem.folder_name.$touch(): null"
                                        label="Name"
                                        append-icon="mdi-rename-box"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-alert v-if="error" dense type="error">
                                    {{error}}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-divider class="primary"/>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="success" @click="save">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="error white--text"
                >
                    <v-icon color="white" class="mr-2">mdi-delete</v-icon>
                    You sure you want to delete this item?
                </v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="closeDelete"
                    >Cancel
                    </v-btn
                    >
                    <v-btn color="success" @click="deleteItemConfirm"
                    >OK
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card style="margin-top: 16px;">
            <v-card-text>
                <v-container style="max-width: 72vw">
                    <Drag v-model="folders" draggable=".folder_items" @start="drag=true" @end="onDragEnd" class="row">
                        <v-col cols="sm-6 col-md-4 col-lg-3" :key="fol.id" v-for="fol in folders" class="folder_items">
                            <v-container style="position: relative;">
                                <button class="edit_folder" @click="editItem(fol)">
                                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-pencil-outline theme--light"
                                       style="color: blue; font-size: 20px"></i>
                                </button>
                                <button class="delete_folder" @click="deleteItem(fol)">
                                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-delete-outline theme--light"
                                       style="color: red; font-size: 20px"></i>
                                </button>
                                <v-container @click="redirectToFolderDetailsView(fol.id)"
                                             style="border: 1px solid #C4C4C4; border-radius: 4px; margin: 10px; padding: 20px 2vw 5px 2vw; text-align: center; ">
                                    <v-img :src="fol.thumbnail_url" height="80px" width="100%"
                                           style="object-fit: cover; border-radius: 4px"/>
                                    <p style="margin-top: 20px; font-size: 18px; color: black; font-weight: 400">{{
                                        fol.folder_name }}</p>
                                </v-container>
                            </v-container>
                        </v-col>
                    </Drag>
                </v-container>
            </v-card-text>
        </v-card>
        <!--        <v-data-table
                        :headers="headers"
                        :items="data"
                        sort-by="calories"
                        class="elevation-3"
                >
                    <template v-slot:item.popup_image_url="{item}">
                        <v-avatar class="profile my-2" color="grey" size="80" tile @click="openImage(item.popup_image_url)">
                            <v-img contain v-if="item.popup_image_url" :src="item.popup_image_url"></v-img>
                        </v-avatar>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small @click="deleteItem(item)" class="error&#45;&#45;text">
                            mdi-delete
                        </v-icon>
                    </template>
                    <template v-slot:no-data>
                        <v-progress-circular
                                v-if="loading"
                                indeterminate
                                color="secondary"
                        ></v-progress-circular>
                        <v-icon
                                v-else
                                x-large
                                color="grey lighten-1"
                        >
                            mdi-tray-remove
                        </v-icon>

                    </template>
                </v-data-table>-->
    </div>
</template>
<script>
    import {required} from 'vuelidate/lib/validators'
    import {validationMixin} from 'vuelidate'
    import draggable from 'vuedraggable'

    export default {
        name: 'DailyImageFolder',
        mixins: [validationMixin],
        data: () => ({
            imgdialog: false,
            selectedSrc: null,
            headers: [
                {
                    text: 'Daily Image',
                    value: 'daily_image_url',
                    sortable: false,
                    align: 'start'
                },
                {text: 'Name', value: 'name'},
                {text: 'Actions', value: 'actions', sortable: false},
            ],
            folders: [],
            apiUrl: process.env.VUE_APP_API_URL,
            loading: true,
            dialog: false,
            dialogDelete: false,
            error: '',
            editedIndex: -1,
            editedItem: {
                folder_name: '',
                thumbnail_url: null
            },
            defaultItem: {
                folder_name: '',
                thumbnail_url: null
            },
            selectedImage: null,
        }),
        created() {
            this.getItems();
        },
        methods: {
            onDragEnd(item) {
                let updateData = {
                    order_sequence: item.newIndex + 1
                };
                this.$Axios({
                    method: 'put',
                    url: 'nettazy-daily/folder/' + this.folders[item.newIndex].id,
                    data: updateData,
                })
                    .then(() => {
                        this.getItems();
                        this.close();
                    })
                    .catch((e) => {
                        console.log(e)
                    });
            },
            openImage(src) {
                this.selectedSrc = src
                this.imgdialog = true
            },
            editItem(item) {
                console.log(item);
                this.selectedImage = item.thumbnail_url;
                this.editedIndex = this.folders.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.editedItem.popup_image_url = null
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.folders.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                this.deleteFolder(this.editedItem.id)
                this.closeDelete();
            },
            close() {
                this.dialog = false;
                this.selectedImage = null;
                this.$v.$reset()
                this.error = ''
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            save() {
                this.editedIndex == -1 ? this.addItem() : this.updateItem()
            },
            //Banners API Calls
            getImage() {
                let formData = new FormData();
                formData.append('folder_thumbnail_file', this.editedItem.thumbnail_url);
                this.$Axios({
                    method: 'post',
                    url: 'nettazy-daily/add-folder-thumbnail',
                    data: formData,
                    headers: {'Content-Type': 'multipart/form-data'},
                })
                    .then(({data}) => {
                        if (data.success) {
                            this.selectedImage = data.data.folder_thumbnail_image_url;
                        } else {
                            console.log(data.msg);
                            alert(data.msg);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        alert(e.response?.data?.msg ?? 'Something went wrong!');
                    });
            },
            getItems() {
                this.selectedImage = null;
                this.loading = true
                this.$Axios
                    .get('nettazy-daily/folder/get/all')
                    .then((res) => {
                        if (res.data['success']) {
                            this.folders = res.data['data'];
                        } else {
                            alert(res.data['msg']);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            addItem() {

                this.error = ''
                this.$v.$touch()
                console.log(this.$v)
                if (this.$v.$error) {
                    return;
                }
                let data = {}
                data.folder_name = this.editedItem.folder_name;
                data.thumbnail_url = this.selectedImage;

                this.$Axios
                    .post('nettazy-daily/folder', data)
                    .then(() => {
                        this.getItems();
                        this.close();
                    })
                    .catch((e) => {
                        console.log(e)
                    });
            },
            updateItem() {
                this.error = ''
                this.$v.$touch()

                if (this.$v.$error) {
                    return
                }
                let updateData = {};
                if (this.editedItem.folder_name) updateData.folder_name = this.editedItem.folder_name;
                if (this.editedItem.thumbnail_url) updateData.thumbnail_url = this.editedItem.thumbnail_url;
                console.log('adding item : ', updateData);
                this.$Axios({
                    method: 'put',
                    url: 'nettazy-daily/folder/' + this.editedItem.id,
                    data: updateData,
                })
                    .then(() => {
                        this.getItems();
                        this.close();
                    })
                    .catch((e) => {
                        console.log(e)
                    });
            },
            deleteFolder(id) {
                this.$Axios
                    .delete('nettazy-daily/folder/' + id)
                    .then(() => {
                        this.getItems();
                    })
                    .catch((e) => {
                        console.log(e);
                    })
            },
            redirectToFolderDetailsView(id) {
                this.$router.push('/nettazy-daily/folder-images/' + id);
            }
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'New Folder' : 'Edit Folder';
            },
            formIcon() {
                return this.editedIndex === -1 ? 'mdi-plus' : 'mdi-edit';
            },
            filtredItems() {
                if (this.filter) return this.desserts.filter(item => item.name.includes(this.filter) || item.license.includes(this.filter) || item.email.includes(this.filter) || item.db.includes(this.filter))
                else return this.desserts
            },
            //// Vuelidate Errors
            nameErrors() {
                const errors = []
                if (!this.$v.editedItem.folder_name.$dirty) return errors
                !this.$v.editedItem.folder_name.required && errors.push('Name is required !')
                return errors
            },
            img_urlErrors() {
                const errors = []
                if (!this.$v.editedItem.popup_image_url.$dirty) return errors
                !this.$v.editedItem.popup_image_url.required && errors.push('Popup Image is required !')
                return errors
            },
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        validations: {
            editedItem: {
                folder_name: {
                    required
                },
                thumbnail_url: {
                    required,
                }
            },
        },
        components: {
            Drag: draggable
        }
    };
</script>
<style scoped>
    button.delete_folder {
        position: absolute;
        top: 30px;
        right: 10px;
        z-index: 9;
        background: #fff;
        border-radius: 50%;
        padding: 7px;
        box-shadow: 0 0 8px 2px #ccc;
    }

    button.edit_folder {
        position: absolute;
        top: 75px;
        right: 10px;
        z-index: 9;
        background: #fff;
        border-radius: 50%;
        padding: 7px;
        box-shadow: 0 0 8px 2px #ccc;
    }
</style>